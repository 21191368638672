import {useState, useEffect} from 'react';
import {getRemainingTimeUntilMsTimestamp} from '../../utils/CountdownTimerUtils';

const defaultRemainingTime = {
    seconds: '00',
    minutes: '00',
    hours: '00',
    days: '00'
}

const CountdownTimer = ({countdownTimestampMs}) => {
    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateRemainingTime(countdownTimestampMs);
        }, 1000);
        return () => clearInterval(intervalId);
    },[countdownTimestampMs]);

    function updateRemainingTime(countdown) {
        setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    }
    const timeStyle = {
        padding: '7px',
        fontSize: '25px',
        backgroundColor: '#00a07e',
        color: '#ffffff',
        borderRadius: '3px',
        textAlign: 'center'
    }
    return(
        <div className="d-flex">
            <div className="mx-2 d-flex flex-column align-content-center">
                <time style={timeStyle}>{remainingTime.days}</time>
                <div className="text-center">Days</div>
            </div>
            <div className="mx-2 d-flex flex-column align-content-center">
                <time style={timeStyle}>{remainingTime.hours}</time>
                <div className="text-center">Hrs</div>
            </div>
            <div className="mx-2 d-flex flex-column align-content-center">
                <time style={timeStyle}>{remainingTime.minutes}</time>
                <div className="text-center">Mins</div>
            </div>
            <div className="mx-2 d-flex flex-column align-content-center">
                <time style={timeStyle}>{remainingTime.seconds}</time>
                <div className="text-center">Secs</div>
            </div>
        </div>
    );
}

export default CountdownTimer;